import React, { createContext, useEffect, useMemo, useState } from "react";

const hasLocalStorage =
  typeof window !== `undefined` && typeof localStorage !== "undefined";
const isKey = "is-wishlist";
const maxElements = 50;

const defVals = {
  wishlist: {},
  wishlistSize: 0,
  addToWishlist: () => {},
  removeFromWishlist: () => {},
  maxElements: maxElements,
  isOpen: false,
  setIsOpen: (v) => {},
  isInviaRichiestaOpen: false,
  setInviaRichiesta: () => {},
  isContattaciOpen: false,
  setContattaci: () => {},
};

export const WishlistContext = createContext(defVals);

export const WishlistProvider = ({ children }) => {
  const [wishlist, setWishlist] = useState(
    hasLocalStorage ? JSON.parse(localStorage.getItem(isKey) ?? "{}") : {}
  );

  const [isOpen, setIsOpen] = useState(false);
  const [isInviaRichiestaOpen, setInviaRichiesta] = useState(false);
  const [isContattaciOpen, setContattaci] = useState(false);

  const wishlistSize = useMemo(() => Object.keys(wishlist).length, [wishlist]);

  const flushWishlist = () => {
    if (hasLocalStorage) {
      try {
        localStorage.setItem(isKey, JSON.stringify(wishlist));
      } catch (e) {
        // silently fail
      }
    }
  };

  useEffect(() => {
    flushWishlist();
  }, [wishlist]);

  const addToWishlist = (k, obj) => {
    if (wishlistSize >= maxElements) {
      return false;
    }
    setWishlist((old) => ({
      ...old,
      [k]: obj,
    }));
    return true;
  };

  const removeFromWishlist = (k) => {
    if (k in wishlist) {
      setWishlist((old) => {
        delete old[k];
        return {
          ...old,
        };
      });
    }
    return false;
  };

  return (
    <WishlistContext.Provider
      value={{
        wishlist,
        wishlistSize,
        addToWishlist,
        removeFromWishlist,
        maxElements,
        isOpen,
        setIsOpen,
        isInviaRichiestaOpen,
        setInviaRichiesta,
        isContattaciOpen,
        setContattaci,
      }}
    >
      {children}
    </WishlistContext.Provider>
  );
};
