import { navigate } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import { slugs2url, subcat2urlpart } from "../resources/categorie";

const defVals = {
  locale: "it",
  changeLang: (locale) => {},
};

const staticPagesList = [
  { it: "/chi-siamo", en: "/en/about-us" },
  { it: "/contatti", en: "/en/contact-us" },
  { it: "/le-nostre-lame", en: "/en/our-blades" },
  { it: "/metallo-duro", en: "/en/hard-metal" },
  { it: "servizi-di-affilatura", en: "/en/sharpening-services" },
  { it: "/trattamento-frozen", en: "/en/frozen-treatment" },
  { it: "/privacy-policy", en: "/en/privacy-policy" },
];

// does not include the default italian one
const supportedLocale = new Set(["en"]);

export const LanguageContext = React.createContext(defVals);

export const LanguageProvider = ({ children, location }) => {
  const possibleLocale = location.pathname.split("/")[1];
  const locale =
    possibleLocale && supportedLocale.has(possibleLocale)
      ? possibleLocale
      : "it";

  const getAlternatePage = (l) => {
    //console.log("got l = " + l);
    if (l === "it" || supportedLocale.has(l)) {
      //console.log(location.pathname);
      const path = location.pathname;
      // check cats and prods pages
      const [matchCat] = Object.values(slugs2url).filter((s) =>
        path.startsWith(s[locale])
      );
      if (matchCat) {
        // check subcats
        const [matchSubCat] = Object.values(subcat2urlpart).filter((s) =>
          path.includes(s[locale])
        );
        if (matchSubCat) {
          return path
            .replace(matchCat[locale], matchCat[l])
            .replace(matchSubCat[locale], matchSubCat[l]);
        } else {
          return path.replace(matchCat[locale], matchCat[l]);
        }
      } else {
        // other pages
        const [matchStatic] = staticPagesList.filter((s) =>
          path.startsWith(s[locale])
        );
        if (matchStatic) {
          return matchStatic[l];
        } else {
          // default to home
          return l === "it" ? "/" : `/${l}`;
        }
      }
    }
  };

  const changeLang = (l) => {
    navigate(getAlternatePage(l));
  };

  const [itPath, enPath] =
    locale === "en"
      ? [getAlternatePage("it"), location?.pathname]
      : [location?.pathname, getAlternatePage("en")];

  //console.log(itPath, enPath);

  return (
    <LanguageContext.Provider value={{ locale, changeLang }}>
      <Helmet>
        <link
          rel="alternate"
          hreflang="it"
          href={"https://internationalsaws.it" + itPath}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={"https://internationalsaws.it" + enPath}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={"https://internationalsaws.it" + itPath}
        />
      </Helmet>
      {children}
    </LanguageContext.Provider>
  );
};
