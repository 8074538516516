// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chi-siamo-js": () => import("./../../../src/pages/chi-siamo.js" /* webpackChunkName: "component---src-pages-chi-siamo-js" */),
  "component---src-pages-contatti-js": () => import("./../../../src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-en-about-us-js": () => import("./../../../src/pages/en/about-us.js" /* webpackChunkName: "component---src-pages-en-about-us-js" */),
  "component---src-pages-en-contact-us-js": () => import("./../../../src/pages/en/contact-us.js" /* webpackChunkName: "component---src-pages-en-contact-us-js" */),
  "component---src-pages-en-frozen-treatment-js": () => import("./../../../src/pages/en/frozen-treatment.js" /* webpackChunkName: "component---src-pages-en-frozen-treatment-js" */),
  "component---src-pages-en-hard-metal-js": () => import("./../../../src/pages/en/hard-metal.js" /* webpackChunkName: "component---src-pages-en-hard-metal-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-our-blades-js": () => import("./../../../src/pages/en/our-blades.js" /* webpackChunkName: "component---src-pages-en-our-blades-js" */),
  "component---src-pages-en-privacy-policy-js": () => import("./../../../src/pages/en/privacy-policy.js" /* webpackChunkName: "component---src-pages-en-privacy-policy-js" */),
  "component---src-pages-en-sharpening-services-js": () => import("./../../../src/pages/en/sharpening-services.js" /* webpackChunkName: "component---src-pages-en-sharpening-services-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-le-nostre-lame-js": () => import("./../../../src/pages/le-nostre-lame.js" /* webpackChunkName: "component---src-pages-le-nostre-lame-js" */),
  "component---src-pages-metallo-duro-js": () => import("./../../../src/pages/metallo-duro.js" /* webpackChunkName: "component---src-pages-metallo-duro-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-servizi-di-affilatura-js": () => import("./../../../src/pages/servizi-di-affilatura.js" /* webpackChunkName: "component---src-pages-servizi-di-affilatura-js" */),
  "component---src-pages-trattamento-frozen-js": () => import("./../../../src/pages/trattamento-frozen.js" /* webpackChunkName: "component---src-pages-trattamento-frozen-js" */),
  "component---src-templates-categoria-js": () => import("./../../../src/templates/categoria.js" /* webpackChunkName: "component---src-templates-categoria-js" */),
  "component---src-templates-prodotto-js": () => import("./../../../src/templates/prodotto.js" /* webpackChunkName: "component---src-templates-prodotto-js" */),
  "component---src-templates-sottocategoria-js": () => import("./../../../src/templates/sottocategoria.js" /* webpackChunkName: "component---src-templates-sottocategoria-js" */)
}

