import "./src/styles/global.css";
import React from "react";
import { WishlistProvider } from "./src/context/wishlist-context";
import { LanguageProvider } from "./src/context/language-context";

export const wrapPageElement = ({ element, props }) => (
  <LanguageProvider {...props}>{element}</LanguageProvider>
);

export const wrapRootElement = ({ element }) => (
  <WishlistProvider>{element}</WishlistProvider>
);
