exports.categories = {
  lama: [
    "incisore",
    "portatili",
    "multilame",
    "lamello",
    "sezionatrici",
    // "massicci",
    // "legni",
    // "laminati",
    // "plastiche",
    // "metalli",
    // "ferrosi",
    "universali",
  ],
  coltello: ["rasanti", "inserti", "incastri", "pialla", "reversibili"],
  punte: [
    "cerniere",
    "ciechi",
    "passanti",
    "svasatori",
    "foratrice-svasatore",
    "elicoidali",
  ],
  frese: ["updown", "z2", "z3", "serrature"],
  //elettroutensili: [],
  //semilavorati: ["profilare", "barrette", "placchette", "denti"],
  accessori: ["mandrini", "pinze"],
  affilatura: [],
};

// categoria 2 url includes locale prefix
exports.slugs2url = {
  accessori: { en: "/en/accessories", it: "/accessori" },
  coltello: { en: "/en/disposable-knives", it: "/coltelli" },
  //elettroutensili: { en: "/en/power-tools", it: "/elettroutensili" },
  frese: { en: "/en/router-cutters", it: "/frese" },
  lama: { en: "/en/sawblades", it: "/lame" },
  punte: { en: "/en/drill-bits", it: "/punte-per-foratrici" },
  //semilavorati: { en: "/en/raw-materials", it: "/prodotti-semilavorati" },
  affilatura: { en: "/en/sharpening-services", it: "/servizi-di-affilatura" },
};

// sottocategoria 2 url
exports.subcat2urlpart = {
  incisore: { en: "/scoring", it: "/lame-incisori" },
  portatili: {
    en: "/portable-sawblade",
    it: "/lame-circolari-per-macchine-portatili",
  },
  multilame: { en: "/multirippping-sawblade", it: "/lame-per-multilama" },
  lamello: { en: "/brazed-cutter-joint", it: "/lamello" },
  sezionatrici: { en: "/panel-sizing-sawblade", it: "/lame-sezionatrici" },
  universali: { en: "/universal-sawblade", it: "/lame-universali" },
  rasanti: { en: "/square-spurs", it: "/coltelli-rasanti" },
  inserti: { en: "/disposable-inserts", it: "/inserti-per-coltelli" },
  incastri: { en: "/grooving-inserts", it: "/coltelli-per-incastri" },
  pialla: { en: "/planner-knives", it: "/coltelli-per-pialla" },
  reversibili: { en: "/disposable-knives", it: "/coltelli-reversibili" },
  cerniere: { en: "/hinges", it: "/punte-per-cerniere" },
  ciechi: { en: "/blind-holes", it: "/punte-per-fori-ciechi" },
  passanti: { en: "/through-holes", it: "/punte-per-fori-passanti" },
  svasatori: { en: "/countersinks", it: "/svasatori-per-punte" },
  "foratrice-svasatore": {
    en: "/dowel-drills-with-countersink",
    it: "/punte-foratrici-con-svasatore",
  },
  elicoidali: { en: "/twist-drill", it: "/punte-elicoidali" },
  updown: { en: "/updown", it: "/frese-updown" },
  z2: { en: "/z2", it: "/frese-z2" },
  z3: { en: "/z3", it: "/frese-z3" },
  serrature: { en: "/hardware-slots", it: "/frese-per-scasso-serrature" },
  //profilare: { en: "/profiling", it: "/coltelli-a-profilare" },
  //barrette: { en: "/blank-tips", it: "/barrette" },
  //placchette: { en: "/tips-for-grooves", it: "/placchette-per-incastro" },
  //denti: { en: "/tips", it: "/denti-lama" },
  mandrini: { en: "/chucks", it: "/mandrini" },
  pinze: { en: "/collets", it: "/pinze-elastiche" },
  //duro: { en: "/solid-carbide", it: "/duro" },
  //diamante: { en: "/pcd", it: "/diamante" },
};

// categoria e sottocategoria slug 2 nome
exports.slugs2name = {
  accessori: { en: "Accessories", it: "Accessori e Ricambi" },
  coltello: { en: "Knives", it: "Coltelli" },
  elettroutensili: { en: "Power Tools", it: "Elettroutensili" },
  frese: { en: "Router Cutters", it: "Frese" },
  lama: { en: "Blades", it: "Lame" },
  punte: { en: "Drill Bits", it: "Punte Per Foratrici" },
  semilavorati: { en: "Raw Materials", it: "Prodotti Semilavorati" },
  affilatura: { en: "Sharpening Services", it: "Servizi di Affilatura" },

  //duro: { en: "Solid Carbide", it: "Metallo Duro" },
  //diamante: { en: "PCD", it: "Diamante" },

  rasanti: { en: "Square Spurs", it: "Rasanti" },
  inserti: { en: "Disposable Inserts", it: "Inserti" },
  incastri: { en: "Grooving Inserts", it: "Coltelli per Incastri" },
  pialla: { en: "Planner Knives", it: "Coltelli per Pialla" },
  reversibili: { en: "Disposable Knives", it: "Coltelli reversibili" },

  mandrini: { en: "Chuck", it: "Mandrini" },
  pinze: { en: "Collets", it: "Pinze Elastiche" },

  updown: { en: "Up and Down", it: "Up and Down" },
  z2: { en: "Z2", it: "Z2" },
  z3: { en: "Z3", it: "Z3" },
  serrature: { en: "Hardware Slots", it: "Scasso serrature" },

  incisore: { en: "Scoring", it: "Incisore" },
  portatili: {
    en: "Saw Blades For Portable Machines",
    it: "Circolari per macchine portatili",
  },
  multilame: { en: "Multi-ripping Sawblades", it: "Multilame" },
  lamello: { en: "Brazed Cutter Joint", it: "Lamello" },
  sezionatrici: { en: "Panel Sizing Sawblade", it: "Sezionatrici" },
  // massicci: { en: "Hard Wood", it: "Legni massicci" },
  // legni: { en: "Composites Wood", it: "Legni e compositi" },
  // laminati: { en: "Laminate and Bilaminate", it: "Laminati e bilaminati" },
  // plastiche: { en: "Plastic Materials", it: "Plastiche e derivati" },
  // metalli: { en: "Non Ferrrous Metals", it: "Metalli non ferrosi" },
  // ferrosi: { en: "Ferrrous Metals", it: "Metalli ferrosi" },
  universali: { en: "Universal Blades", it: "Lame universali" },

  cerniere: { en: "Hinges", it: "Cerniere" },
  ciechi: { en: "Blind Holes", it: "Fori Ciechi" },
  passanti: { en: "Through Holes", it: "Fori Passanti" },
  svasatori: { en: "Countersink Bits", it: "Punte con svasatore" },
  "foratrice-svasatore": {
    en: "Countersink Drill Bits",
    it: "Punte foratrici con svasatore",
  },
  elicoidali: { en: "Solid Carbide Twist Drills", it: "Punte Elicoidali" },

  //profilare: { en: "Profiling Knives", it: "Coltelli a profilare" },
  //barrette: { en: "Blank Tips", it: "Barrette" },
  //placchette: { en: "Tips", it: "Placchette per incastro" },
  //denti: { en: "Tips for Sawblade", it: "Denti Lama" },
};

// materiali
exports.materials = {
  "metalli-non-ferrosi": { en: "Non-ferrous Metal", it: "Metalli non ferrosi" },
  "metalli-ferrosi": { en: "Ferrous Metal", it: "Metalli ferrosi" },
  alluminio: { en: "Aluminum", it: "Alluminio" },
  "legno-tenero": { en: "Soft Wood", it: "Legno tenero" },
  "legno-duro": { en: "Hard Wood", it: "Legno duro" },
  multistrato: { en: "Plywood", it: "Multistrato" },
  truciolare: { en: "Chipboard", it: "Truciolare" },
  impiallacciato: { en: "Veeneered", it: "Impiallacciato" },
  laminato: { en: "Laminate", it: "Laminato" },
  bilaminato: { en: "Bilaminate", it: "Bilaminato" },
  "materiali-plastici": { en: "Plastic Materials", it: "Materiali plastici" },
  plexiglass: { en: "Plexiglass", it: "Plexiglass" },
  MDF: { en: "MDF", it: "MDF" },
  "materiali-polimerici": {
    en: "Polymeric materials",
    it: "Materiali polimerici",
  },
};
